import * as yup from "yup";
import { Input, message } from "antd";
import { Fragment, useEffect, useState } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import ReCAPTCHA from "react-google-recaptcha";
import GenericPhoneSearch from "./GenericPhoneSearch";
import ZipInfos from "./ZipInfos";
import moment from "moment";

import {
  INTERNAL_API_ENDPOINT,
  INTERNAL_CLOSERS_DNC_API_ENDPOINT,
  INTERNAL_VOIP_BLOCKED_API_ENDPOINT,
  INTERNAL_INVALID_PHONES_API_ENDPOINT,
  DATE_FORMAT,
  INTERNAL_LITIGATORS_API_ENDPOINT,
  INTERNAL_OLD_CLOSERS_DNC_API_ENDPOINT,
} from "../Constants";
import Litigators from "./Litigators";
import BlackList from "./BlackList";

yup.addMethod(yup.string, "onlyDigits", function () {
  return this.matches(/^\d+$/, "Only digits allowed.");
});

const { Search } = Input;

function SearchPhoneNumber({ uiSettings }) {
  const [captchaKey, setCaptchaKey] = useState(0);
  const [searchedPhone, setSearchedPhone] = useState("");
  const [searchedName, setSearchedName] = useState("");
  // const [phoneInput, setPhoneInput] = useState("");

  // const cleanPhoneNumber = (input) => {
  //   return input.replace(/\D/g, "").slice(0, 10);
  // };

  // const handlePhoneChange = (e) => {
  //   const cleanedInput = cleanPhoneNumber(e.target.value);
  //   setPhoneInput;
  //   cleanedInput;
  // };

  useEffect(() => {
    uiSettings.requireCaptchaVerification &&
      uiSettings.showStringCaptcha &&
      loadCaptchaEnginge(4, "white");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const targetDatasets = [
    {
      prefixLabel: "Social Analytics Suggests: ",
      apiEndpoint: INTERNAL_API_ENDPOINT,
      messageCallback: (response) => {
        if (response.data) {
          return `${
            response.data.phoneNumber
          } is HIGH RISK POSSIBLE TCPA is added on ${moment(
            response.data.createdDate
          ).format(DATE_FORMAT)}`;
        } else {
          return `${searchedPhone} is Good`;
        }
      },
    },
    {
      prefixLabel: "Closers DNC Suggests: ",
      apiEndpoint: INTERNAL_CLOSERS_DNC_API_ENDPOINT,
      messageCallback: (response) => {
        if (response.data) {
          return `${
            response.data.phoneNumber
          } is disposed in DNC by several closers added on ${moment(
            response.data.createdDate
          ).format(DATE_FORMAT)}, PLEASE DO NOT TRANSFER!`;
        } else {
          return `${searchedPhone} is Not found, Good to GO!`;
        }
      },
    },
    {
      prefixLabel: "Old Closers DNC Suggests: ",
      apiEndpoint: INTERNAL_OLD_CLOSERS_DNC_API_ENDPOINT,
      messageCallback: (response) => {
        if (response.data) {
          return `${response.data.phoneNumber} : FOUND - only proceed after supervision if satisfied and no red flags.`;
        } else {
          return `${searchedPhone} is Not found, Good to GO!`;
        }
      },
    },
    {
      prefixLabel: "Invalid Phones Suggests: ",
      apiEndpoint: INTERNAL_INVALID_PHONES_API_ENDPOINT,
      messageCallback: (response) => {
        if (response.data) {
          return `${response.data.phoneNumber} is disposed as INVALID by several closers, PLEASE DO NOT TRANSFER!`;
        } else {
          return `${searchedPhone} is Not found, Good to GO!`;
        }
      },
    },
    {
      prefixLabel: "VOIP Blocked Suggests: ",
      apiEndpoint: INTERNAL_VOIP_BLOCKED_API_ENDPOINT,
      messageCallback: (response) => {
        if (response.data) {
          return `${response.data.phoneNumber} is disposed as Voip Blocked by several closers, PLEASE DO NOT TRANSFER!`;
        } else {
          return `${searchedPhone} is Not found, Good to GO!`;
        }
      },
    },
  ];

  const targetNameDatasets = [
    {
      prefixLabel: "Kahoot Names Analytics Suggests: ",
      apiEndpoint: INTERNAL_LITIGATORS_API_ENDPOINT,
      messageCallback: (response) => {
        if (response.data) {
          return `${searchedName} seems like a kahoot name!`;
        } else {
          return `${searchedName} is Not found`;
        }
      },
    },
  ];

  const reloadCaptcha = () => {
    setCaptchaKey((prevKey) => prevKey + 1);
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleReCaptchaChange = () => {
    setIsCaptchaVerified(true);
  };

  async function onSearchPhoneNumber(values) {
    setSearchedPhone("");
    setSearchedName("");
    let user_captcha_value =
      document.getElementById("user_captcha_input")?.value;

    if (uiSettings.requireCaptchaVerification && uiSettings.showStringCaptcha) {
      if (validateCaptcha(user_captcha_value) !== true) {
        alert("Captcha Does Not Match");
        return;
      }
    }

    if (uiSettings.requireCaptchaVerification && !isCaptchaVerified) {
      message.error("Captcha verification failed. Please try again.");
      return;
    }

    let isnum = /^\d+$/.test(values);
    if (!isnum) {
      message.error("Please enter digits.");
      return;
    }
    if (!values) {
      message.error("Phone is required.");
      return;
    }
    if (values.length !== 10) {
      message.error(`Error : 10 digits are required.`);
      return;
    }

    setSearchedPhone(values);
    setErrorMessage("");
    setIsCaptchaVerified(false);
    reloadCaptcha();
  }

  async function onSearchName(values) {
    setSearchedName("");
    setSearchedPhone("");
    let user_captcha_value =
      document.getElementById("user_captcha_input")?.value;

    if (uiSettings.requireCaptchaVerification && uiSettings.showStringCaptcha) {
      if (validateCaptcha(user_captcha_value) !== true) {
        alert("Captcha Does Not Match");
        return;
      }
    }

    if (uiSettings.requireCaptchaVerification && !isCaptchaVerified) {
      message.error("Captcha verification failed. Please try again.");
      return;
    }
    if (!values) {
      message.error("Please Name.");
      return;
    }
    setSearchedName(values);
    setErrorMessage("");
    setIsCaptchaVerified(false);
    reloadCaptcha();
  }

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          overflowY: "scroll",
        }}
      >
        <div>
          <div style={{ marginBottom: "5px" }}>
            <h1 style={{ color: "Green" }}> Search Phone Number </h1>
            <Search
              // value={phoneInput}
              placeholder="Phone Number"
              style={{ width: "300px" }}
              allowClear
              enterButton="Search"
              size="medium"
              onSearch={onSearchPhoneNumber}
              // onChange={handlePhoneChange}
            />
          </div>
          <div>
            <div style={{ marginBottom: "5px" }}>
              <h1 style={{ color: "Green" }}> Search Name </h1>
              <Search
                placeholder=" Name"
                style={{ width: "300px" }}
                allowClear
                enterButton="Search"
                size="medium"
                onSearch={onSearchName}
              />
            </div>
          </div>

          {uiSettings.requireCaptchaVerification &&
            uiSettings.showStringCaptcha && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <LoadCanvasTemplate />

                <input
                  style={{ margin: "10px" }}
                  placeholder="Enter Captcha Value"
                  id="user_captcha_input"
                  name="user_captcha_input"
                  type="text"
                />
              </div>
            )}

          {uiSettings.requireCaptchaVerification && (
            <ReCAPTCHA
              key={captchaKey}
              size="normal"
              sitekey="6Le5p40nAAAAACf9tffCBfoNW95wQCaO9SFpKDJE"
              onChange={handleReCaptchaChange}
            />
          )}
        </div>
        {searchedName &&
          targetNameDatasets.map((config) => {
            return (
              <div key={config.prefixLabel} style={{ margin: "2px" }}>
                <GenericPhoneSearch
                  prefixLabel={config.prefixLabel}
                  phoneNumber={searchedName}
                  apiEndpoint={config.apiEndpoint}
                  messageCallback={config.messageCallback}
                />
              </div>
            );
          })}

        {searchedPhone && (
          <>
            <ZipInfos phoneNumber={searchedPhone} />

            {/* <Litigators
              prefixLabel="BlackListed DB Suggests: "
              phoneNumber={searchedPhone}
              fakeBlackList={true}
            />

            <Litigators
              prefixLabel="DNC Suggests: "
              phoneNumber={searchedPhone}
              fakeDncScrub={true}
            /> */}

            <Litigators
              prefixLabel="Litigators DB Suggests: "
              phoneNumber={searchedPhone}
            />
            {/* <BlackList
              prefixLabel="BlackListed DB Suggests: "
              phoneNumber={searchedPhone}
            /> */}
            {targetDatasets.map((config) => {
              return (
                <div key={config.prefixLabel} style={{ margin: "2px" }}>
                  <GenericPhoneSearch
                    prefixLabel={config.prefixLabel}
                    phoneNumber={searchedPhone}
                    apiEndpoint={config.apiEndpoint}
                    messageCallback={config.messageCallback}
                  />
                </div>
              );
            })}
          </>
        )}
        {errorMessage && (
          <h2>
            <span style={{ color: "red", whiteSpace: "pre-line" }}>Error:</span>{" "}
            <span style={{ whiteSpace: "pre-line" }}>{errorMessage}</span>
          </h2>
        )}
      </div>
    </Fragment>
  );
}

export default SearchPhoneNumber;
