import { Fragment, useEffect, useState } from "react";
import {
  LITIGATORS_API_ENDPOINT,
  LITIGATORS_API_USER,
  LITIGATORS_API_PASSWORD,
} from "../Constants";

function Litigators({
  prefixLabel,
  phoneNumber,
  fakeBlackList = false,
  fakeDncScrub = false,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(true);
  const [error, setError] = useState("");

  function getLitigatorsResponseMessage(response) {
    if ("match" in response?.data) {
      let result = response?.data?.match[phoneNumber];
      if (result) {
        let type = result.type;
        let status = result.status;

        return `${phoneNumber} Found : (${type ? type : status})`;
      } else {
        return `${phoneNumber} is Clean`;
      }
    } else {
      if ("clean" in response.data) {
        return `${phoneNumber} is Clean`;
      } else {
        return `ERROR! ${response.data.message}`;
      }
    }
  }

  const getDncResponseMessage = (response) => {
    if ("match" in response.data) {
      const result = response.data.match[phoneNumber];
      if (result) {
        const { type } = result;
        return processResponseMessageForDnc(type);
      }
    }
    if ("clean" in response.data) {
      return `${phoneNumber} : Clean`;
    } else {
      return `ERROR: ${response.data.message}`;
    }
  };

  function processResponseMessageForDnc(type) {
    if (type.includes("TCPA troll") || type.includes("DNC Complainers")) {
      return `${phoneNumber} Found : TCPA`;
    } else if (type.includes("Federal DNC")) {
      return `${phoneNumber} Found : DO NOT CALL`;
    } else {
      return `${phoneNumber} Found : Clean`;
    }
  }

  function getBlackListResponseMessage(response) {
    if (!response) {
      return;
    }
    if ("match" in response?.data) {
      let result = response?.data?.match[phoneNumber];
      if (result) {
        let type = result.type;
        // let status = result.status;

        if (type && type.includes("Federal DNC")) {
          return `${phoneNumber} Found : DNC`;
        } else {
          return `${phoneNumber} Found : BLACKLISTED`;
        }
      }
    }
    return `${phoneNumber} Found : Clean`;
  }

  async function fetchData() {
    const axios = require("axios").default;
    try {
      const response = await axios.post(
        LITIGATORS_API_ENDPOINT,
        {
          phone_number: phoneNumber,
          type: "all",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          auth: {
            username: LITIGATORS_API_USER,
            password: LITIGATORS_API_PASSWORD,
          },
        }
      );

      setResponse(response);
    } catch (error) {
      setError(`Error : ${JSON.stringify(error.data)} `);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  function getFinalMessage(fakeBlackList, fakeDncScrub) {
    if (isLoading) {
      return `Loading, Please Wait!`;
    }
    if (error) {
      return error;
    }
    if (!response?.data) {
      return " ";
    }
    if (response && fakeBlackList) {
      return getBlackListResponseMessage(response);
    }
    if (response && fakeDncScrub) {
      return getDncResponseMessage(response);
    }
    return getLitigatorsResponseMessage(response);
  }
  return (
    <Fragment>
      <h2 key="prefixLabel">
        <span style={{ color: "red" }}>{prefixLabel}:</span>
        {getFinalMessage()}
      </h2>

      <h2 key="BlackListed DB Suggests: ">
        <span style={{ color: "red" }}>BlackListed DB Suggests: </span>
        {getFinalMessage(true, false)}
      </h2>

      <h2 key="DNC Suggests: ">
        <span style={{ color: "red" }}>DNC Suggests: </span>
        {getFinalMessage(false, true)}
      </h2>
    </Fragment>
  );
}
export default Litigators;
