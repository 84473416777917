import { useEffect, useState } from "react";
import config from "../../config/config";

function GenericPhoneSearch({
  prefixLabel,
  phoneNumber,
  apiEndpoint,
  messageCallback,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("Loading, Please Wait!");

  async function fetchData() {
    const axios = require("axios").default;
    try {
      let url = apiEndpoint + phoneNumber;

      const response = await axios.get(`${url}`, {
        headers: {
          "Access-Control-Allow-Origin": "no-cors",
          ApiKey: config.API_KEY,
        },
      });

      if (messageCallback) {
        const val = messageCallback(response);
        setMessage(JSON.stringify(val));
      } else {
        setMessage(`${phoneNumber} is ${JSON.stringify(response.data)}`);
      }
      // setInternalNameMessage(`${JSON.stringify(response.data)}`);
    } catch (error) {
      setMessage(`Error : ${JSON.stringify(error.data)} `);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  return (
    <h2>
      <span style={{ color: "red" }}>{prefixLabel}</span>
      {isLoading ? `Loading, Please Wait!` : message}
    </h2>
  );
}
export default GenericPhoneSearch;
