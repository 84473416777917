// apiConstants.js

export const SUPER_ADMIN_API_KEY = "XZQwkfEEcCHJ9aPghpAQ4bnnraQjFnVFul0EidlZS4";
export const SUBSCRIPTION_API_KEY =
  "XZQwkfEEcCHJ9aPghpAQ4bnnraQjFnVFul0EidlZS4";
export const BLACKLIST_API_KEY = "fjacNt7dkxsYnVY8E4NE";
// export const INTERNAL_API_KEY =
//   // "cc33d4c34err-0f7e8900lab-4892-97d1jk7ll-mmcc7e921f0290";
//   "eaeae7f0-01bf-4ff4-86a2-3d6857ec0061";

// export const API_ENDPOINT =
//   "https://tcpaportalapi20211224001646.azurewebsites.net/api";

export const API_ENDPOINT =
  "https://tcpa-tools-client-api.azurewebsites.net/api";

// "https://localhost:44343/api";

const proxyUrl =
  "https://technationals-cors-anywhere-c044beaa3884.herokuapp.com/";

export const UI_SETTINGS_API_ENDPOINT = `${API_ENDPOINT}/clients/ui-settings`;

const SEARCH_PHONE_URL = "SearchPhone";

// Blacklist API Endpoints
export const BLACKLIST_API_ENDPOINT = `https://technationals-cors-anywhere-c044beaa3884.herokuapp.com/https://api.blacklistalliance.net/lookup?key=${BLACKLIST_API_KEY}&ver=v3&resp=json&phone=`;

// Internal API Endpoints
export const INTERNAL_API_ENDPOINT = `${API_ENDPOINT}/${SEARCH_PHONE_URL}/`;
export const INTERNAL_CLOSERS_DNC_API_ENDPOINT = `${API_ENDPOINT}/${SEARCH_PHONE_URL}/ClosersDnc/`;
export const INTERNAL_OLD_CLOSERS_DNC_API_ENDPOINT = `${API_ENDPOINT}/${SEARCH_PHONE_URL}/OldClosersDnc/`;

export const INTERNAL_REPORTED_DNC_API_ENDPOINT = `${API_ENDPOINT}/${SEARCH_PHONE_URL}/ReportedDnc/`;
export const INTERNAL_VOIP_BLOCKED_API_ENDPOINT = `${API_ENDPOINT}/${SEARCH_PHONE_URL}/VoipBlockedPhones/`;
export const INTERNAL_INVALID_PHONES_API_ENDPOINT = `${API_ENDPOINT}/${SEARCH_PHONE_URL}/InvalidPhones/`;
export const INTERNAL_ZIP_INFO_API_ENDPOINT = `${API_ENDPOINT}/${SEARCH_PHONE_URL}/ZipsInfo/`;
export const INTERNAL_TLDS_API_ENDPOINT = `${API_ENDPOINT}/${SEARCH_PHONE_URL}/dtb/`;

// Litigators API Endpoints
export const LITIGATORS_API_ENDPOINT = `${proxyUrl}https://api.tcpalitigatorlist.com/scrub/phone/`;

export const LITIGATORS_API_USER = "tcpa_OUNZ3ABOc7";
export const LITIGATORS_API_PASSWORD = "mK70 kp6l x27j itTe MhBM SDAa";

// Dnscrub API Endpoints
export const DNSCRUB_API_ENDPOINT = `https://www.dncscrub.com/app/main/rpc/scrub?`;
export const DNSCRUB_API_KEY = "061031627F2A410897B8A6ED4B5BDCA37F94E34D869D";
export const DNSCRUB_VERSION = "5";
export const DNSCRUB_OUTPUT = "json";

export const DATE_FORMAT = "M/D/YYYY h:mm:ss A";

export const INTERNAL_LITIGATORS_API_ENDPOINT = `${API_ENDPOINT}/${SEARCH_PHONE_URL}/Litigators/`;
export const INTERNAL_LITIGATOR_NAME_API_ENDPOINT = `${API_ENDPOINT}/${SEARCH_PHONE_URL}/NameSearch/`;
