import { useEffect, useState } from "react";
import { INTERNAL_ZIP_INFO_API_ENDPOINT } from "../Constants";
import config from "../../config/config";

function ZipInfos({ phoneNumber }) {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState();

  async function fetchData() {
    const axios = require("axios").default;
    try {
      let url = INTERNAL_ZIP_INFO_API_ENDPOINT + phoneNumber;

      const response = await axios.get(`${url}`, {
        headers: {
          "Access-Control-Allow-Origin": "no-cors",
          ApiKey: config.API_KEY,
        },
      });
      setMessage(response.data);
    } catch (error) {
      setMessage(`Error : ${JSON.stringify(error.data)} `);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);
  return (
    <h2>
      {isLoading ? (
        `Loading, Please Wait!`
      ) : (
        <div
          style={{
            border: "1px dashed black",
            marginTop: "10px",
            padding: "10px",
          }}
        >
          <span style={{ color: "red" }}>Zip: </span>
          {message?.zip ? message.zip : "N/A"}
          {",   "}
          <span style={{ color: "red" }}> City: </span>
          {message?.city ? message.city : "N/A"}
          {","}
          <span style={{ color: "red" }}> County: </span>
          {message?.county ? message.county : "N/A"}
          {",   "}
          <span style={{ color: "red" }}> State: </span>
          {message?.state ? message.state : "N/A"}
        </div>
      )}
    </h2>
  );
}
export default ZipInfos;
